import ko from 'knockout';
import errorTracker from 'core/error-tracker-plugin/errorTrackerPlugin';
import router from 'app/model/router';
import translations from 'app/model/translationsConfig';
import botsConfig from 'app/service/botsConfig';
import infinityTracking from 'cx/service/infinityTracking/infinityTracking';
import userTracking from 'cx/service/userTracking';
import globalSettings from 'app/service/globalSettings';
import pixelTracking from 'cx/service/pixelTracking/pixelTrackingService';
import { extendHttpConfig } from 'app/model/httpConfigExtension';
import { session } from 'candidate-verification/service/user-session/session';

// TODO: review and eliminate global imports
import 'jquery';
import 'html5-history-api';

export function init() {
    extendHttpConfig();

    Promise.all([
        translations.init(),
        globalSettings.loadGlobalSettings(),
    ])
        .then(() => session.extend()
            .catch((error) => {
                if (error) {
                    console.warn(error);
                }
            }))
        .then(() => {
            userTracking.initAnalyticalTracking();
            userTracking.initFunctionalFeatures();
            errorTracker.init();
            botsConfig.init();
            infinityTracking.init();
            pixelTracking.init();

            /* eslint global-require: 0*/
            require(['app/module/cx/config/module'], () => {
                router.sync();
                ko.applyBindings();
            });
        });
}
